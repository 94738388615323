import React from 'react'

import SpinnerStyled from './styledComponent'

class Spinner extends React.PureComponent {
  render() {
    return (
      <SpinnerStyled>
        <svg className="spinner" viewBox="0 0 66 66">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </SpinnerStyled>
    )
  }
}

export default Spinner
