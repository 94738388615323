import styled, { keyframes } from 'styled-components'

const rotator = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
`

const dash = keyframes`
  0% { stroke-dashoffset: 90; }
  50% {
   stroke-dashoffset: 90/4;
   transform:rotate(135deg);
  }
  100% {
   stroke-dashoffset: 90;
   transform:rotate(450deg);
  }
`

export default styled.div`
  display: flex;
  .spinner {
    width: 40px;
    height: 40px;
    animation: ${rotator} 1.4s linear infinite;
  }
  .path {
    stroke-dasharray: 90;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: ${(props) => props.theme.jadeGreen};
    animation: ${dash} 1.4s ease-in-out infinite;
  }
`
